import React, {ReactComponentElement, Suspense, useCallback, useContext, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import { faBarsFilter as fasBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination, Skeleton, Stack } from '@mui/material';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep'
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Checkers from '../../../helpers/class/Checkers';
import {
  fetchPropertiesAction,
  setApplyParams,
  setOffset,
  setPageNumber,
  setUiSearchBarDivHeight
} from '../../../redux/actions';
import NoticeHeader from '../../ui/headers/NoticeHeader';
import ViewPort from '../../ViewPort';

import Filters from './partials/Filters';
import {fetchBuilderSettingByKey, getPropertyViewType} from "../../../helpers/MethodHelper";
import StickyBox from "react-sticky-box";

const List = dynamic( () => import( './partials/List' ), { suspense: true });
const Thumbs = dynamic( () => import( './partials/Thumbs' ), { suspense: true });
const BookingSearchBar = dynamic( () => import( '../index/BookingSearchBar' ), { suspense: true });
const GoogleMapContent = dynamic( () => import( '../../GoogleMapContent' ), { suspense: true });

import homePin from '../../../assets/images/homePin.svg';
import Image from "next/image";
import SwipeableDrawerFilters from "../property/content/columned/right/SwipeableDrawerFilters";
import Calendar from "../../../helpers/class/Calendar";
import SwipperWrapper from "./partials/SwipperWrapper";
import {AnyComponent} from "styled-components/native/dist/types";
import {SwiperSlide} from "swiper/react";
import SingleServiceContent from "../property/partials/services/SingleServiceContent";

export default function Properties ( props ) {

  const isRelatedSection = props.hasOwnProperty('isRelatedSection') ? props.isRelatedSection : false

  const isMobile = useSelector(state => state['appSettings']['isMobile'])

  Calendar.setProps(props);

  const calendarMaterialTheme = Calendar.getCalendarMaterialTheme;

  const calendar = {
    customTheme: calendarMaterialTheme,
  };

  const isListingPage = useSelector( state => state['propertiesParams']['isListingPage']);

  const showBookingBar = props.hasOwnProperty('showBookingBar') ? props.showBookingBar : true

  const propertiesIndex = props.hasOwnProperty('propertiesIndex') ? props.propertiesIndex : false

  const showTotalPropertiesCount = props.hasOwnProperty('showTotalPropertiesCount') ? props.showTotalPropertiesCount : true

  const thumbClassInherited = props.hasOwnProperty('thumbClassInherited') ? props.thumbClassInherited : false

  const showFiltersBar = props.hasOwnProperty('showFiltersBar') ? props.showFiltersBar : true

  const useSwipper = props.hasOwnProperty('useSwipper') ? props.useSwipper : false

  const slidesPerViewDesktop = props.hasOwnProperty('slidesPerViewDesktop') ? props.slidesPerViewDesktop : 2.5

  const propertiesContainerExtraClass = props.hasOwnProperty('propertiesContainerExtraClass') ? props.propertiesContainerExtraClass : 'px-2'

  const hasDestination = props.hasOwnProperty('destinationId') ? true : false

  const isDesktop = useSelector( state => state['appSettings']['isDesktop']);

  const router = useRouter();

  const dispatch = useDispatch()

  const propertiesData = useSelector(state => state['propertiesData']);

  const propertiesParams = useSelector(state => state['propertiesParams']);

  const propertiesOffset = useSelector(state => state['propertiesParams']['offset']);

  const loadingProperties = useSelector(state => state['propertiesParams']['loadingProperties']);

  const paramsApplied = useSelector(state => state['propertiesParams']['paramsApplied']);

  const checkin = useSelector(state => state['propertiesParams']['checkin'])

  const checkout = useSelector(state => state['propertiesParams']['checkout'])

  const uiSearchBarDivHeight = useSelector(state => state['uiData']['searchBarDivHeight'])

  const unavailabelPropertiesViewType = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_unavailable_view_type')
  );

  const getPaginationNumber = (count) => {
    return Math.ceil(count / 24)
  }

  const paginationCounter = useSelector(state => getPaginationNumber(state['propertiesData']['count']));

  const properties = useSelector(state => state['propertiesData']['properties']);

  const pageNumber = useSelector(state => state['propertiesParams']['pageNumber']);

  const offsetHandler = (page_number = pageNumber) => {
    let offset = (page_number - 1) * 24;
    dispatch(setOffset(offset))

    return offset
  }

  const app = props.app;
  const builder = props.builder;
  let centerObj = {};
  let property_id = 0;
  const skeletonProperties = [];

  const enableMapClustering = app.builder.builder_propertylist_map_enable_cluster

  const randomizePin = app.builder.builder_propertylist_map_enable_randomize_pin

  useEffect( () => {
    // console.log("randomizePin", randomizePin)
  })

  const [mouseMapClick, setMouseMapClick] = useState(false);
  const mapEnabled = props.googleMapShow && Checkers.valueToBoolean(builder.builder_properties_map);
  const propertiesMapEnabled = mapEnabled ? Checkers.valueToBoolean(builder.builder_propertylist_enable_map) : false;
  const mapInit = Checkers.googleMapsPropertyInit(props.googleMapApiKey, propertiesMapEnabled, 0, 0);
  const [showMap, setShowMap] = useState(mapInit);

  // useEffect( () => {
  //   console.log("propertiesMapEnabled", mapEnabled, propertiesMapEnabled, builder.builder_propertylist_enable_map)
  // })

  const galleryRef = useRef();
  const [galleryHeight, setGalleryHeight] = useState(285);

  const propertiesViewType = builder.builder_properties_view_type.toString();
  const viewTypeClass = propertiesViewType == '207' ? 'Properties-List' : 'Properties-Thumb';
  let ratio = 13;
  if (propertiesViewType == '207') {
    ratio = 11;
  } else {
    ratio = showMap ? 13 : 11;
  }
  const listClass = showMap ? 'col-12 mb-3' : 'col-12 col-sm-12 col-md-10 col-lg-10 mb-3';
  let thumbClass = showMap ? 'col-12 col-sm-6 col-md-6 mb-3' : 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3';
  if (thumbClassInherited!==false) {
    thumbClass = thumbClassInherited
  } else {
    if (!showMap && propertiesViewType == '206') {
      switch (builder.builder_properties_columns) {
        case 2:
        case '2':
          thumbClass = 'col-12 col-sm-6 col-md-6 mb-3';
          break;
        case 3:
        case '3':
          thumbClass = 'col-12 col-sm-6 col-md-6 col-lg-4 mb-3';
          ratio = 13;
          break;
        case 4:
        case '4':
        default:
          thumbClass = 'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3';
          ratio = 15;
          break;
      }
    }
  }

  for (let i = 0; i < 24; i++) {
    skeletonProperties.push(
        <div key={i.toString()} className={propertiesViewType == '207' ? listClass : thumbClass}>
          <div className={`Skeleton Properties ${viewTypeClass} Properties-Border Properties-Shadow row w-100 m-0`}>
            <div className={`${propertiesViewType == '207' ? 'col-12 col-sm-6 col-md-5' : 'col-12'} p-0`}>
              <Skeleton variant="rectangular" width={'100%'} className="b-rad-md" style={{height: galleryHeight}}/>
            </div>
            <div
                className={`Properties__body ${propertiesViewType == '207' ? 'col-12 col-sm-6 col-md-7' : 'col-12'} px-3 py-2`}>
              <Skeleton variant="text" width={92} height={18}/>
              <Skeleton variant="text" width={132} height={25}/>
              <Skeleton variant="text" width={250} height={35}/>
            </div>
          </div>
        </div>
    );
  }

  /**
   * -> PROPERTIES GOOGLE MAP
   **/
  properties.forEach((property) => {
    if (Checkers.isValidNumber(property.lat) && Checkers.isValidNumber(property.lng)) {
      centerObj = {
        lat: parseFloat(property.lat),
        lng: parseFloat(property.lng)
      }
      property_id = property.property_id;
      return;
    }
  });
  const [relocatedCenter, setRelocatedCenter] = useState(centerObj);
  const [previousProperty, setPreviousProperty] = useState(property_id);

  const changeMarkerOnClick = (lat, lng, property_id) => {
    changeMarker(lat, lng, property_id);
  }
  const changeMarkerOnHover = (lat, lng, property_id) => {
    if (infoWindowState == null) {
      changeMarker(lat, lng, property_id);
    }
  }
  const changeMarker = useCallback((lat, lng, property_id) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    centerObj = {};
    if (Checkers.isValidNumber(lat) && Checkers.isValidNumber(lng)) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      centerObj = {lat: parseFloat(lat), lng: parseFloat(lng)};
    }
    const thisChild = document.getElementById('property_marker_' + property_id);
    const prevChild = document.getElementById('property_marker_' + previousProperty);
    if (thisChild) {
      thisChild.parentElement.style.zIndex = '1';
    }
    if (prevChild) {
      prevChild.parentElement.style.zIndex = '0';
    }
    setRelocatedCenter(() => centerObj);
    setPreviousProperty(property_id);
  }, [relocatedCenter, previousProperty]);

  const [pixelOffsetX, setPixelOffsetX] = useState(0);
  const [pixelOffsetY, setPixelOffsetY] = useState(0);
  const [secondState, setSecondState] = useState(true);
  const [infoWindowState, setInfoWindowState] = useState(null);
  const openInfoWindow = useCallback((clientWidth, clientHeight) => {
    setPixelOffsetX(() => clientWidth);
    setPixelOffsetY(() => clientHeight);
    setSecondState(true);
  }, []);

  const updateInfoWindowState = useCallback(function (state) {
    setSecondState(false);
    setMouseMapClick(true);
    setInfoWindowState(() => state);
  }, []);
  /**
   * -> PROPERTIES GOOGLE MAP END
   **/

  const mapRef = useRef();
  const searchBarRef = useRef();
  const filtersDivRef = useRef();
  const propertiesRef = useRef();

  const [searchBarDivHeight, setSearchBarDivHeight] = useState(0);

  const updateSearchDivHeight = useCallback((searchBarDiv, bodyWidth) => {
    if (showBookingBar) {
      if (bodyWidth > 768 && searchBarDiv!=undefined) {
        if (searchBarDiv!=undefined && searchBarDiv.hasOwnProperty('searchBarDiv')) {
          setSearchBarDivHeight(() => searchBarDiv.clientHeight);
          dispatch(setUiSearchBarDivHeight(searchBarDiv.clientHeight))
        }
      } else {
        setSearchBarDivHeight(() => 0);
      }
    } else {
      setSearchBarDivHeight(() => 0);
    }
  }, []);

  const builderMapWidth = 100 - useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertylist_map_width')
  );

  const setDivWidthHeightTop = useCallback((filtersDiv, propertiesDiv, mapDiv, searchBarDiv, bodyHeight, bodyWidth) => {
    if (mapDiv) {
      // SET MAP HEIGHT / WIDTH AND STICKY TOP POSITION
      mapDiv.style.top = searchBarDivHeight + 'px';
      mapDiv.style.height = (bodyHeight - searchBarDivHeight) + 'px';
      mapDiv.style.width = (bodyWidth - propertiesDiv.clientWidth - 1) + 'px';
      // TOGGLE PROPERTIES DIV WIDTH
      if (propertiesDiv) {
        if (bodyWidth <= 991) {
          propertiesDiv.style.maxWidth = 'initial';
          setShowMap(false);
        } else if (bodyWidth > 991 && mapInit) {
          if (!useSwipper) {
            propertiesDiv.style.maxWidth = builderMapWidth + '%';
          }
        }
      }
    }
  }, [mapInit, searchBarDivHeight]);

  const fetchProperties = useCallback((params = propertiesParams, page_number = pageNumber, offset = propertiesOffset) => {
    const asPath = router.asPath.split('/page')[0];
    if (page_number > 1)
      window.history.pushState("", "", (asPath == "/" ? "" : asPath) + `/page/${page_number}`);
    else
      window.history.pushState("", "", asPath);

    params.offset = offset
    dispatch<any>(fetchPropertiesAction(params, null, uiSearchBarDivHeight))
    dispatch(setApplyParams(0))
    setMouseMapClick(false);
  }, [propertiesOffset, router.asPath]);

  const pageNumberHandler = (page) => {
    dispatch(setPageNumber(parseInt(page)))
    let offset = offsetHandler(page)
    fetchProperties(propertiesParams, page, offset)
  }

  useEffect(() => {
    let mapDiv;
    let filtersDiv;
    let searchBarDiv;
    let propertiesDiv;
    let galleryDiv;

    if (showBookingBar && document !== undefined && window !== undefined) {
      const body = document.querySelector('body').getBoundingClientRect();
      if (body) {
        mapDiv = mapRef?.current;
        filtersDiv = filtersDivRef?.current;
        searchBarDiv = searchBarRef?.current;
        propertiesDiv = propertiesRef?.current;
        galleryDiv = galleryRef?.current;

        if (galleryDiv) setGalleryHeight((galleryDiv.clientWidth * ratio) / 16);

        updateSearchDivHeight(searchBarDiv, body.width);
        setDivWidthHeightTop(filtersDiv, propertiesDiv, mapDiv, searchBarDiv, body.height, body.width);
        if (body.width > 768 && searchBarDiv!=undefined) {
          setSearchBarDivHeight(() => searchBarDiv.clientHeight);
        } else {
          setSearchBarDivHeight(() => 0);
        }
        window.addEventListener('resize', function () {
          if (this.innerWidth > 991 && mapInit) {
            setShowMap(true);
          }

          if (galleryDiv) setGalleryHeight((galleryDiv.clientWidth * ratio) / 16);

          updateSearchDivHeight(searchBarDiv, this.innerWidth);
          setDivWidthHeightTop(filtersDiv, propertiesDiv, mapDiv, searchBarDiv, this.innerHeight, this.innerWidth);
        });
        // SET SHADOW ONLY ON SCROLL, OIN FILTERS DIV
        window.addEventListener('scroll', function () {
          if (filtersDiv && searchBarDiv) {
            if (searchBarDiv.getBoundingClientRect().top == 0 || filtersDiv.getBoundingClientRect().top == 0) {
              filtersDiv.style.boxShadow = '0 8px 6px -6px #ccc';
              filtersDiv.style.webkitBoxShadow = '0 8px 6px -6px #ccc';
            } else {
              filtersDiv.style.boxShadow = 'none';
              filtersDiv.style.webkitBoxShadow = 'none';
            }
            if (searchBarDivHeight === 0) {
              updateSearchDivHeight(searchBarDiv, this.innerWidth);
            }
          }
        });
      }
    }
  }, [mapInit, ratio, router, searchBarDivHeight, setDivWidthHeightTop, updateSearchDivHeight, builderMapWidth]);

  const borderRadiusForBorder = builder.builder_properties_radius - 2;
  const containerClass = showMap ? 'container-fluid p-0' : 'container p-0'
  const filtersDivClass = `Properties-Index__properties-filters bg-master-lightest sticky-top py-3 px-2 mb-3 w-100 m-0`;
  const searchbarClass = 'bg-white border-top border-bottom sticky-md-top w-100 Properties-Index__searchbar';

  const renderProperty = (property, key, useSkeleton = true) => {

    const availabilityStatus = property.availabilityStatus != undefined ? property.availabilityStatus : null;
    return (
        <>
          {
            useSkeleton ?
                <>{
                  Checkers.isValidDate(checkin) && Checkers.isValidDate(checkout) && property.availabilityStatus != null &&
                  (
                      (properties[key - 1] && properties[key - 1]['availabilityStatus'] == 1 && property.availabilityStatus != 1) ||
                      (!properties[key - 1] && property.availabilityStatus != 1)
                  ) ?
                      <>
                        <NoticeHeader
                            title={'The properties bellow are not available for the selected date range'}/>
                      </>
                      :
                      ''
                }
                  <ViewPort
                      key={key}
                      parentClassName={propertiesViewType == '207' ? listClass : thumbClass}
                      skeletonItem={<div
                          className={`Skeleton Properties ${viewTypeClass} Properties-Border Properties-Shadow row w-100 m-0`}>
                        <div
                            className={`${propertiesViewType == '207' ? 'col-12 col-sm-6 col-md-5' : 'col-12'} p-0`}>
                          <Skeleton variant="rectangular" width={'100%'} className="b-rad-md"
                                    style={{height: galleryHeight}}/>
                        </div>
                        <div
                            className={`Properties__body ${propertiesViewType == '207' ? 'col-12 col-sm-6 col-md-7' : 'col-12'} px-3 py-2`}>
                          <Skeleton variant="text" width={92} height={18}/>
                          <Skeleton variant="text" width={132} height={25}/>
                          <Skeleton variant="text" width={250} height={35}/>
                        </div>
                      </div>}
                  >
                    {
                      (isRelatedSection || getPropertyViewType(availabilityStatus, propertiesViewType, unavailabelPropertiesViewType) == '206') ?
                        <>
                          <Thumbs
                              key={property.property_id.toString()}
                              property_id={property.property_id}
                              property_title={property.property_title}
                              property_moto={property.moto}
                              property_location={property.location}
                              property_lat={property.lat}
                              property_lng={property.lng}
                              availabilityStatus={availabilityStatus}
                              minmimStayData={property.minmimStayData}
                              images={property.featured_images}
                              thumbClass={thumbClass}
                              changeMarkerOnHover={changeMarkerOnHover}
                              galleryHeight={galleryHeight}
                              galleryRef={galleryRef}
                              sourcePath={property.sourcePath}
                              minimumPrice={(property.hasOwnProperty('range_price') && property.range_price!=null) ? property.range_price : property.minimum_price}
                              capacity={property.capacity}
                              bedrooms={property.bedrooms}
                              tags={property.tags}/>
                        </>
                        :
                        <>
                          <List
                              key={property.property_id.toString()}
                              property_id={property.property_id}
                              property_title={property.property_title}
                              property_moto={property.moto}
                              property_location={property.location}
                              property_lat={property.lat}
                              property_lng={property.lng}
                              availabilityStatus={availabilityStatus}
                              minmimStayData={property.minmimStayData}
                              images={property.featured_images}
                              listClass={listClass}
                              changeMarkerOnHover={changeMarkerOnHover}
                              galleryHeight={galleryHeight}
                              sourcePath={property.sourcePath}
                              capacity={property.capacity}
                              bedrooms={property.bedrooms}
                              minimumPrice={(property.hasOwnProperty('range_price') && property.range_price!=null) ? property.range_price : property.minimum_price}
                              galleryRef={galleryRef}/>
                        </>
                    }
                  </ViewPort>
                </>
                :
                <>
                  {
                    Checkers.isValidDate(checkin) && Checkers.isValidDate(checkout) && property.availabilityStatus != null &&
                    (
                        (properties[key - 1] && properties[key - 1]['availabilityStatus'] == 1 && property.availabilityStatus != 1) ||
                        (!properties[key - 1] && property.availabilityStatus != 1)
                    ) ?
                        <NoticeHeader
                            title={'The properties bellow are not available for the selected date range'}/>
                        :
                        ''
                  }
                    {
                      (isRelatedSection || getPropertyViewType(availabilityStatus, propertiesViewType, unavailabelPropertiesViewType) == '206') ?
                        <>
                          <Thumbs
                              key={property.property_id.toString()}
                              property_id={property.property_id}
                              property_title={property.property_title}
                              property_moto={property.moto}
                              property_location={property.location}
                              property_lat={property.lat}
                              property_lng={property.lng}
                              availabilityStatus={availabilityStatus}
                              minmimStayData={property.minmimStayData}
                              images={property.featured_images}
                              thumbClass={thumbClass}
                              changeMarkerOnHover={changeMarkerOnHover}
                              galleryHeight={galleryHeight}
                              galleryRef={galleryRef}
                              sourcePath={property.sourcePath}
                              minimumPrice={(property.hasOwnProperty('range_price') && property.range_price!=null) ? property.range_price : property.minimum_price}
                              capacity={property.capacity}
                              bedrooms={property.bedrooms}
                              tags={property.tags}/>
                        </>
                        :
                        <>
                          <List
                              key={property.property_id.toString()}
                              property_id={property.property_id}
                              property_title={property.property_title}
                              property_moto={property.moto}
                              property_location={property.location}
                              property_lat={property.lat}
                              property_lng={property.lng}
                              availabilityStatus={availabilityStatus}
                              minmimStayData={property.minmimStayData}
                              images={property.featured_images}
                              listClass={listClass}
                              changeMarkerOnHover={changeMarkerOnHover}
                              galleryHeight={galleryHeight}
                              sourcePath={property.sourcePath}
                              capacity={property.capacity}
                              bedrooms={property.bedrooms}
                              minimumPrice={(property.hasOwnProperty('range_price') && property.range_price!=null) ? property.range_price : property.minimum_price}
                              galleryRef={galleryRef}/>
                        </>
                    }
                </>
          }

        </>
    )
  }

  const propertiesComponent : Array<ReactComponentElement<AnyComponent>> = []

  properties.map((property, index) => {
    propertiesComponent.push(<SwiperSlide key={'related-property-' + property.property_id}>{renderProperty(property, index, false)}</SwiperSlide>)
  })

  return (
      <>
        {Checkers.isValidPositiveNumber(builder.builder_properties_radius) &&
            <style jsx global>
              {`
                .Properties,
                .Properties .Properties__gallery,
                .Properties .Properties__gallery > div {
                  border-radius: ${builder.builder_properties_radius}px;
                  -moz-border-radius: ${builder.builder_properties_radius}px;
                  -webkit-border-radius: ${builder.builder_properties_radius}px;
                }

                .Properties .Properties__gallery .swiper-custom-prev,
                .Properties .Properties__gallery .swiper-custom-next {
                  border-radius: ${(app.builder.builder_properties_radius / 0.5) * 2}% !important;
                  -moz-border-radius: ${(app.builder.builder_properties_radius / 0.5) * 2}% !important;
                  -webkit-border-radius: ${(app.builder.builder_properties_radius / 0.5) * 2}% !important;
                }
              `}
            </style>
        }
        {Checkers.valueToBoolean(builder.builder_properties_shadow) &&
            <style jsx global>
              {`
                .Properties.Properties-Shadow {
                  box-shadow: 0 6px 20px rgba(0, 0, 0, .2);
                  -moz-box-shadow: 0 6px 20px rgba(0, 0, 0, .2);
                  -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, .2);
                }
              `}
            </style>
        }
        {Checkers.valueToBoolean(builder.builder_properties_border) &&
            <style jsx global>{`
              .Properties.Properties-Border {
                border: 1px solid #dee2e6;
              }
            `}</style>
        }
        {(Checkers.valueToBoolean(builder.builder_properties_border) || Checkers.valueToBoolean(builder.builder_properties_shadow)) &&
            <style jsx global>
              {`
                .Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery,
                .Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery > div {
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  -moz-border-bottom-left-radius: 0 !important;
                  -moz-border-bottom-right-radius: 0 !important;
                  -webkit-border-bottom-left-radius: 0 !important;
                  -webkit-border-bottom-right-radius: 0 !important;
                }

                .Properties.Properties-List.Properties-Border.Properties-Shadow .Properties__gallery,
                .Properties.Properties-List.Properties-Border.Properties-Shadow .Properties__gallery > div {
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  -moz-border-top-right-radius: 0 !important;
                  -moz-border-bottom-right-radius: 0 !important;
                  -webkit-border-top-right-radius: 0 !important;
                  -webkit-border-bottom-right-radius: 0 !important;
                }

                .Properties.Properties-Thumb .Properties__body {
                  border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -moz-border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -moz-border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -webkit-border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -webkit-border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                }

                .Properties.Properties-List .Properties__body {
                  border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -moz-border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -moz-border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -webkit-border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  -webkit-border-bottom-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                }

                @media only screen and (max-width: 575px) {
                  .Properties.Properties-List.Properties-Border.Properties-Shadow .Properties__gallery,
                  .Properties.Properties-List.Properties-Border.Properties-Shadow .Properties__gallery > div {
                    border-bottom-left-radius: ${0}px !important;
                    border-bottom-right-radius: ${0}px !important;
                    border-top-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -moz-border-bottom-left-radius: ${0}px !important;
                    -moz-border-bottom-right-radius: ${0}px !important;
                    -moz-border-top-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -moz-border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -webkit-border-bottom-left-radius: ${0}px !important;
                    -webkit-border-bottom-right-radius: ${0}px !important;
                    -webkit-border-top-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -webkit-border-top-right-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  }

                  .Properties.Properties-List .Properties__body {
                    border-top-left-radius: ${0}px !important;
                    border-top-right-radius: ${0}px !important;
                    border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -moz-border-top-left-radius: ${0}px !important;
                    -moz-border-top-right-radius: ${0}px !important;
                    -moz-border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                    -webkit-border-top-left-radius: ${0}px !important;
                    -webkit-border-top-right-radius: ${0}px !important;
                    -webkit-border-bottom-left-radius: ${Checkers.valueToBoolean(builder.builder_properties_border) ? borderRadiusForBorder : builder.builder_properties_radius}px !important;
                  }
                }
              `}
            </style>
        }
        {Checkers.isValidString(builder.builder_properties_bg_color) &&
            <style jsx global>
              {`
                .Properties-Index__properties .Properties-Index__properties-filters-button {
                  border: 1px solid ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff'};
                  color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff'} !important;
                }

                .Properties-Index__properties h1,
                .Properties-Index__properties h2,
                .Properties-Index__properties h3,
                .Properties-Index__properties h4,
                .Properties-Index__properties h4,
                .Properties-Index__properties h6,
                .Properties-Index__properties p,
                .Properties-Index__properties label {
                  color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff'};
                }

                .Destination-Section-Desc,
                .Properties-Index__searchbar,
                .Properties-Index__properties,
                .Properties-Index__properties .Properties-Index__properties-filters,
                .Properties-Index__searchbar .Properties-Index__searchbar-row,
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row,
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row .SearchBar-Form__column .SearchBar-Form__form-group {
                  background-color: ${builder.builder_properties_bg_color} !important;
                }

                .Properties-Index__properties .Properties-Index__properties-filters,
                .Destination-Section-Desc .Destination-Section-Desc__row .Destination-Section-Desc__column *,
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row .SearchBar-Form__column .SearchBar-Form__form-group p,
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row .SearchBar-Form__column .SearchBar-Form__form-group label,
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row .SearchBar-Form__column .SearchBar-Form__form-group div[id$="placeholder"],
                .Properties-Index__searchbar .Properties-Index__searchbar-row .SearchBar-Form .SearchBar-Form__row .SearchBar-Form__column .SearchBar-Form__form-group > div:not(.SearchBar-Form__form-group-select) * {
                  color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff'} !important;
                }

                .Destination-Section-Desc .Destination-Section-Desc__row .Destination-Section-Desc__column button.btn-link.text-link {
                  text-decoration: underline;
                }

                .Properties__body {
                  background-color: ${!Checkers.valueToBoolean(builder.builder_properties_border) && !Checkers.valueToBoolean(builder.builder_properties_shadow) ? builder.builder_properties_bg_color : '#fff'};
                }

                .Properties.Properties-List .Properties__body {
                  height: 100%;
                }

                .Properties .Properties__body > *,
                .Properties .Properties__body div > * {
                  color: ${!Checkers.valueToBoolean(builder.builder_properties_border) && !Checkers.valueToBoolean(builder.builder_properties_shadow) ? Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff' : 'initial'} !important;
                }

                .Properties-Index__Paging ul > li > div,
                .Properties-Index__Paging ul > li > button {
                  color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? '#000' : '#fff'} !important;
                }

                .Properties-Index__Paging ul > li > button:hover {
                  background-color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.1)'} !important;
                }

                .Properties-Index__Paging ul > li > button.Mui-selected {
                  background-color: ${Checkers.valueToBoolean(builder.builder_properties_text_color) ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.2)'} !important;
                }

                .Properties__body {
                  padding: ${Checkers.valueToBoolean(builder.builder_properties_border) || Checkers.valueToBoolean(builder.builder_properties_shadow) ? '1rem' : '1rem 0.575rem'} !important;
                }

                .Properties-Index__properties-filters {
                  border-top: 1px solid #dee2e6;
                }

                .Properties-Index__searchbar {
                  border: none !important;
                }
              `}
            </style>
        }
        {<style jsx global>{`
          .Skeleton.Properties .Properties__body {
            height: auto;
          }
        `}</style>}
        {
          showBookingBar && isDesktop && app.enableBooking ?
              <>
                <section ref={searchBarRef} id="search_bar" className={searchbarClass} style={{zIndex: '1022'}}>
                  {
                    showBookingBar && isDesktop && app.enableBooking ?
                        !(app.bookingSearchbarWidth) && (app.bookingSearchbarPosition || propertiesIndex || hasDestination) ?
                            <div className="Properties-Index__searchbar-row row w-100 m-0 p-0 justify-content-center">
                              <div className="col-12 col-lg-8 p-0">
                                <BookingSearchBar
                                    containerClass={containerClass}
                                    padding={10}
                                    isManager={true}
                                    noShadow={true}
                                    sliderSearchBar={true}
                                    sliderSearchBarFullWidth={true}
                                    designMode={props.designMode}
                                    searchbarThemeColor={app.searchbarThemeColor}
                                    rgbaDesignColor={props.rgbaDesignColor}
                                    searchbarRadius={props.searchbarRadius}
                                    searchbarShadow={props.searchbarShadow}
                                    bookingSearchbarWithBedrooms={props.bookingSearchbarWithBedrooms}
                                    isHomePage={false}
                                />
                              </div>
                            </div>
                            : app.bookingSearchbarWidth ?
                                <div className="Properties-Index__searchbar-row">
                                  <BookingSearchBar
                                      isDesktop={isDesktop}
                                      key={'BookingSearchBar'}
                                      isManager={true}
                                      fullWidthBar={true}
                                      designMode={app.designMode}
                                      bookingSearchbarWithBedrooms={app.hasOwnProperty('bookingSearchbarWithBedrooms') ? !!app.bookingSearchbarWithBedrooms : false}
                                      searchbarThemeColor={app.searchbarThemeColor != null ? app.searchbarThemeColor : null}
                                      searchbarBgColor={app.searchbarBgColor != null && app.searchbarBgColor != '' ? app.searchbarBgColor : null}
                                      position={app.bookingSearchbarPosition}
                                      isHomePage={false}
                                  />
                                </div>
                                :
                                <></>
                        :
                        <></>
                  }
                </section>
              </>
              :<></>
        }
        <section className="Properties-Index__properties bg-master-lightest">
          <div className={containerClass}>
            <div className="row w-100 m-0">
              <div ref={propertiesRef} id="properties" className="col-sm-12 col-lg-12 p-0"
                   style={{maxWidth: showMap && !useSwipper ? builderMapWidth + '%' : 'initial'}}>
                {
                  showFiltersBar ?
                      <>
                        <div id="filters" className={`${filtersDivClass} row justify-content-center`}
                             style={{top: searchBarDivHeight}}>
                          <div className={propertiesViewType == '207' ? 'col-12 col-sm-12 col-md-10' : 'col-12'}>
                            <div className="row justify-content-between align-items-center">
                              {
                                showTotalPropertiesCount ?
                                    <>
                                      {
                                        !loadingProperties ?
                                            <ViewPort
                                                parentClassName={'col-auto'}
                                                skeletonItem={
                                                  <Skeleton variant="rectangular" height={25} width={140} className="b-rad-md"/>
                                                }
                                            >
                                              <div className="col-auto">
                                                {
                                                  Checkers.isValidPositiveNumber(propertiesData['properties_available']) ?
                                                      <span className="fw-bold text-uppercase letter-sp-normal">{propertiesData['properties_available']} properties</span>
                                                      :
                                                      <></>
                                                }
                                              </div>
                                            </ViewPort>
                                            :
                                            <Skeleton variant="rectangular" height={25} width={140} className="b-rad-md"/>
                                      }
                                      {
                                        !loadingProperties ?
                                            <ViewPort
                                                parentClassName={'col-auto'}
                                                skeletonItem={
                                                  <Skeleton variant="rectangular" height={42} width={107} className="b-rad-md"/>
                                                }
                                            >
                                              <div className="col-auto">
                                                {
                                                  <Filters propertiesFilters={props.propertiesFilters}/>
                                                }
                                              </div>
                                            </ViewPort>
                                            :
                                            <Skeleton variant="rectangular" height={42} width={107} className="b-rad-md"/>
                                      }
                                    </>
                                    :
                                    <></>
                              }
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                      </>
                }

                {loadingProperties || Checkers.isValidArray(properties) ?
                    <div
                        className={`Properties row ${propertiesViewType == '207' ? 'justify-content-center' : ''} w-100 m-0 ` + propertiesContainerExtraClass}>
                      {
                        useSwipper ?
                            <>
                              <SwipperWrapper
                                  key={'properties-swiper'}
                                  imageSlider={false}
                                  htmlSlides={propertiesComponent}
                                  paginationBulletsEnabled={false}
                                  slidesPerView={slidesPerViewDesktop}
                                  slidesPerViewLg={3}
                                  slidesPerViewMd={3}
                                  slidesPerViewSm={2}
                                  slidesPerViewXs={1.4}
                                  spaceBetween={10}
                                  enableLoop={false}
                                  galleryHeight={'auto'}
                                  wrapperClassName={'d-navigation '}
                                  enableFancyBox={false}
                                  centeredSlides={false}
                              />
                            </>
                            :
                            <>
                            {
                                !loadingProperties && properties.map((property, key) => {
                                  return renderProperty(property, key)
                                })
                            }
                            </>
                      }
                      {loadingProperties && skeletonProperties.map((elements) => elements)}
                    </div>
                    :
                    <NoticeHeader
                        title={'No properties available'} subtitle={'Please change your search criteria'}/>
                }

                {props.properties_count > 24 && Checkers.isValidArray(properties) ?
                    <div className="Properties-Index__Paging row w-100 m-0 py-5 justify-content-center">
                      <div className="col-auto">
                        <Stack spacing={2}>
                          <Pagination
                              page={pageNumber}
                              count={paginationCounter}
                              onChange={(event, page) => {
                                pageNumberHandler(page)
                              }}
                              getItemAriaLabel={(button, number) => {
                                switch (button) {
                                  case 'page':
                                    return `${number}`
                                  case 'previous':
                                    return 'previous'
                                  case 'next':
                                    return 'next'
                                }
                              }}
                          />
                        </Stack>
                      </div>
                    </div>
                    :
                    <>
                    </>
                }
              </div>
              {showMap &&
                  <div ref={mapRef} id="map" className="sticky-top p-0 hidden-xs hidden-sm hidden-md">
                    {/*<ViewPort*/}
                    {/*    parentClassName={'sticky-top border-top p-0 h-100 hidden-xs hidden-sm hidden-md'}*/}
                    {/*    skeletonItem={*/}
                    {/*      <Skeleton variant="rectangular" className="h-100 w-100"/>*/}
                    {/*    }*/}
                    {/*>*/}
                      {/*<Image*/}
                      {/*    // loading={key == 0 ? 'eager' : 'lazy'}*/}
                      {/*    layout={'fill'}*/}
                      {/*    sizes={'75vw'}*/}
                      {/*    quality={'75'}*/}
                      {/*    src={homePin}*/}
                      {/*    placeholder={'blur'}*/}
                      {/*    blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}*/}
                      {/*/>*/}
                      <GoogleMapContent
                          app={app}
                          designMode={props.designMode}
                          propertiesPois={properties}
                          showMarkerCluster={false}
                          center={relocatedCenter}
                          googleMapApiKey={props.googleMapApiKey}
                          inViewport={true}
                          secondState={secondState}
                          pixelOffsetX={pixelOffsetX}
                          pixelOffsetY={pixelOffsetY}
                          changeMarkerOnClick={changeMarkerOnClick}
                          infoWindowState={infoWindowState}
                          setInfoWindowState={setInfoWindowState}
                          updateInfoWindowState={updateInfoWindowState}
                          openInfoWindow={openInfoWindow}
                          mouseMapClick={mouseMapClick}
                          setMouseMapClick={setMouseMapClick}
                          enableMapClustering={enableMapClustering}
                          randomizePin={randomizePin}
                      />
                    {/*</ViewPort>*/}
                  </div>
              }
            </div>
          </div>
        </section>
        {
            showBookingBar && app.enableBooking && isListingPage && isMobile ?
                <>
                  <SwipeableDrawerFilters calendar={calendar}/>
                </>
                :
                <></>
        }
      </>
  )
}