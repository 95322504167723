import React, { FC, memo, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { CloseButton, Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

import {
  addCollection,
  addDestination,
  addLocation,
  addTag,
  addTheme, addType, removeCollection,
  removeDestination, removeLocation,
  removeTag,
  removeTheme, removeType
} from '../../../redux/actions';
// import cloneDeep from 'lodash/cloneDeep'
// import { useRouter } from 'next/router';
//
// import * as appContext from '../../../../components/AppContext';
// import Checkers from '../../../../helpers/class/Checkers';
// import { addTheme, fetchPropertiesAction, removeTheme, setOffset } from '../../../../redux/actions';

const AsyncSelectWrapper = ( props ) => {
  const appSettings = useSelector( ( state ) => state[props.reducerKey])
  const identifierValues = useSelector( ( state ) => state[props.reducerKey][props.id])

  const values = props.values;

  const [ selectedSearchLocation, setSelectedSearchLocation ]= useState( null );

  const dispatch = useDispatch();

  const defaultOptionsHandler = ( options ) => {
    let fixed_options = [];

    options.length> 0 && options.forEach( ( option ) => {
      let isDisabled = option.properties_count > 0 ? 0 : 1;
      fixed_options.push({
        label: option.title + " (" + option.properties_count + ")",
        value: option.id,
        isDisabled: isDisabled
      })
    })

    return fixed_options
  }

  const loadSearchOptions = ( inputValue, callback ) => {
    const options = [];
    let data = values;

    data = data.filter( ( i ) =>
      i.title.toLowerCase().includes( inputValue.toLowerCase() )
    );

    data.forEach( ( option ) => {
      let isDisabled = option.properties_count > 0 ? 0 : 1;
      options.push({
        label: option.title + " (" + option.properties_count + ")",
        value: option.id,
        isDisabled: isDisabled
      })
    })

    setTimeout( () => {
      callback( options );
    }, 500 )
  }

  const updateSelectedValues = () => {
    let selected_values = [];

    values.forEach( ( option ) => {
      if ( identifierValues.includes( option.id ) ) {
        let isDisabled = option.properties_count > 0 ? 0 : 1;

        selected_values.push({
          label: option.title + " (" + option.properties_count + ")",
          value: option.id,
          isDisabled: isDisabled
        })
      }
    })

    return selected_values;
  }

  const [ selectedValues, setSelectedValues ] = useState( updateSelectedValues() );

  const handleInputChange = ( input ) => {
    // console.log( input )
    let selected_values = [];
    input.forEach( ( val ) => {
      selected_values.push( val.value );
      if ( props.id=="themes" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addTheme( val.value ) )
        }
      }
      if ( props.id=="tags" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addTag( val.value ) )
        }
      }
      if ( props.id=="destinations" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addDestination( val.value ) )
        }
      }
      if ( props.id=="locations" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addLocation( val.value ) )
        }
      }
      if ( props.id=="collections" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addCollection( val.value ) )
        }
      }
      if ( props.id=="gtypes" ) {
        if ( !identifierValues.includes( props.id ) ) {
          dispatch( addType( val.value ) )
        }
      }
    })

    identifierValues.forEach( ( val ) => {
      if ( props.id=="themes" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeTheme( val ) )
        }
      }
      if ( props.id=="tags" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeTag( val ) )
        }
      }
      if ( props.id=="destinations" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeDestination( val ) )
        }
      }
      if ( props.id=="locations" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeLocation( val ) )
        }
      }
      if ( props.id=="collections" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeCollection( val ) )
        }
      }
      if ( props.id=="gtypes" ) {
        if ( !selected_values.includes( val ) ) {
          dispatch( removeType( val ) )
        }
      }
    })
  };

  useEffect( () => {
    setSelectedValues( updateSelectedValues() )
  }, [ identifierValues, setSelectedValues ])


  return (
    <AsyncSelect
      noOptionsMessage={() => 'nothing found'}
      loadingMessage={() => 'searching...'}
      cacheOptions={false}
      defaultOptions={defaultOptionsHandler( values )}
      loadOptions={loadSearchOptions}
      onChange={handleInputChange}
      className="SearchBar-Form__form-group-select"
      isSearchable
      value={selectedValues}
      placeholder={'Select a ' + props.title}
      blurInputOnSelect
      menuPlacement={'bottom'}
      isMulti={true}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
    />
  )
}



export default memo( AsyncSelectWrapper );