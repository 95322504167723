import React, { FC, memo, ReactNode, useContext, useEffect, useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { faArrowUp as falArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { display } from '@mui/system';
import cloneDeep from 'lodash/cloneDeep'
import { useRouter } from 'next/router';
import { isArray } from 'util';

import * as appContext from '../../../../components/AppContext';
import Checkers from '../../../../helpers/class/Checkers';
import {
  addTheme,
  fetchPropertiesAction,
  removeTheme,
  setApplyParams,
  setLoadingProperties,
  setOffset, setPageNumber
} from '../../../../redux/actions';
import AsyncSelectWrapper from '../../../ui/inputs/AsyncSelectWrapper';


interface FiltersModal {
  filters: any
  modalName: string
  className: string
  modalSize?: 'sm' | 'lg' | 'xl'
  closeModal: () => void
  show: boolean
  Title?: ReactNode | null
}

const FiltersModal: FC<FiltersModal> =
    (
      {
        filters,
        modalName,
        className,
        modalSize,
        closeModal,
        show,
        Title
      }
    ) => {
      const [ showSimpleModal, setShowSimpleModal ] = useState<boolean>( show || false );
      const router = useRouter();
      const hasHash = router.asPath.includes( '#' )

      const uiSearchBarDivHeight = useSelector(state => state['uiData']['searchBarDivHeight'])

      const openHandler = () => {
        setShowSimpleModal( true );
      }

      const closeHandler = () => {
        closeModal();
        setShowSimpleModal( false );
      }
      const clickOutSideHandler = () => {
        closeModal();
        setShowSimpleModal( false );
      }

      useEffect( () => {
        if ( show ) openHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [show])

      useEffect( () => {
        if ( !hasHash && showSimpleModal ) clickOutSideHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [hasHash]);

      const propertiesParams = useSelector( state => state['propertiesParams']);

      const dispatch = useDispatch()

      const applyFilters = () => {
        dispatch( setOffset( 0 ) )
        dispatch( setPageNumber( 1 ) )
        dispatch<any>( fetchPropertiesAction( propertiesParams, 0, uiSearchBarDivHeight ) )
        closeHandler()
      }

      return (
        <Modal
          key={1000}
          id={modalName}
          className={className}
          size={modalSize}
          show={showSimpleModal}
          onHide={closeHandler}
          animation={true}
          // onOverlayClick={clickOutSideHandler}
        >
          <Modal.Header key={10001}>
            <CloseButton onClick={closeHandler}/>
            <h3 className={'m-auto'}>{Title}</h3>
          </Modal.Header>
          <div className="modal-body">
            <div className="container">
              <div className="row w-100 p-t-20 no-margin text-left">
                {
                  filters != undefined && filters.map( ( filter, index ) => {
                    return (
                      filter.values.length > 0 &&
                      <>
                        <div className="filter m-b-30" key={index}>
                          <div className="col-12 m-b-10 title">{filter.title}</div>
                          <AsyncSelectWrapper
                            id={filter.id}
                            count={filter.count}
                            title={filter.title}
                            values={filter.values}
                            reducerKey={'propertiesParams'}
                          />
                        </div>
                      </>
                    )
                  })
                }
              </div>
              <div className={"row"}>
                <div className={"col-8"} />
                <div className={"col-4"}>
                  <button aria-label="" onClick={applyFilters} className="btn-design-color btn btn-primary w-100">
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    }

export default memo( FiltersModal );
