import React, { FC, memo, ReactNode, useContext, useEffect, useState } from 'react';
import { faExclamationTriangle as faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface NoticeHeaderInterface  {
    title?: string
    subtitle?: string
}

const NoticeHeader = ({title, subtitle = 'Please select a different date range'} : NoticeHeaderInterface ) => {

  return (
    <>
        <div className="no-result p-l-15 p-r-15 p-t-15 p-b-15 text-master text-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className={'m-r-5 fa-4x hint-text'}/>
            <h3 className="text-master">{title}</h3>
            <p className="text-master fs-16">{subtitle}</p>
        </div>
    </>
  )
}

export default memo( NoticeHeader );