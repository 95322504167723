import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { faBarsFilter as fasBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../../helpers/class/Checkers';
import {
  addStatus,
  countPropertiesForFiltersAction,
  fetchPropertiesFiltersAction,
  removeStatus,
  setPageId
} from '../../../../redux/actions';

import FiltersModal from './FiltersModal';

export default function Filters ({
  propertiesFilters
}) {
  const propertiesParams = useSelector( state => state['propertiesParams']);

  const propertiesFiltersData = useSelector( state => state['propertiesFiltersData']);

  const [ filters, setFilters ] = useState( propertiesFiltersData );

  const dispatch = useDispatch()

  useEffect( () => {
    dispatch<any>( countPropertiesForFiltersAction( propertiesParams, propertiesParams, propertiesFiltersData ) )
  }, [propertiesParams])

  useEffect( () => {
    setFilters( propertiesFiltersData );
  }, [propertiesFiltersData])


  let showFilters = false;
  filters != undefined && filters.length>0 && filters.map( ( filter ) => {
    if ( filter.values.length > 0 ) {
      showFilters = true;
    }
  })

  const [ modalState, setModalState ] = useState( "close" );

  const handleShow = ( state ) => {
    setModalState( state );
  }

  const handleHide = () => {
    setModalState( "close" );
  }

  return (
    showFilters ?
      <>
        <button className="Properties-Index__properties-filters-button btn bg-transparent text-bg-light border-radius-10"   onClick={() => handleShow( 'modalFilters' )}>
          <FontAwesomeIcon className="me-2" icon={fasBarsFilter}/> <h6 className="fw-normal">Filters</h6>
        </button>
        {
          <FiltersModal
            key={100}
            filters={filters}
            modalName={'filterProperties'}
            className="slide_right"
            Title={'Filters'}
            modalSize="lg"
            closeModal={() => {
              handleHide()
            }}
            show={modalState === 'modalFilters'}
          />
        }
      </>
      :
      null
  )
}
